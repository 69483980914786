import React, { useState, useEffect } from 'react'
import Router from 'next/router'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FilledInput from '@material-ui/core/FilledInput'
import Autocomplete from '@material-ui/lab/Autocomplete'
import axios from 'axios'

import cleanup from '../../../helpers/cleanup'
import lookups from '../../../helpers/Lookups'

const OFFERS = [
    { label: 'for Sale', value: 'Sale' },
    { label: 'for Lease', value: 'Lease' },
]
const CLASSES = [
    { label: 'Homes', value: 'Residential' },
    { label: 'Condos', value: 'Condo' },
]

const useStyles = makeStyles((theme) => ({
    submit: {
        marginBottom: theme.spacing(0.5),
        background: '#D9A06E',
        '&:hover': {
            background: '#CA996E',
        },
    },
    advanced: {
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    radio: {
        marginLeft: theme.spacing(3),
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
        },
    },
}))

const SearchBar = ({ available, boundaries }) => {
    const classes = useStyles()

    const [state, setState] = useState({
        class: 'Residential',
        offer: 'Sale',
        boundary: null,
        minPrice: null,
        maxPrice: null,
        minBeds: null,
        minBaths: null,
        style: null,
        type: null,
        maxDom: null,
        searchQuery:null,
    })

    const onChange = (name, value) => setState({ ...state, [name]: value })
    const onInputChange = (name) => (e) => onChange(name, e.target.value)

    const onSubmit = () => {
        let params = {
            ...cleanup(state),
            classes: [state.class],
        }

        if (state.boundary) {
            params.boundaryName = state.boundary.name
            params[state.boundary.type] = state.boundary.code
        }

        if (state.type) {
            state.types = [state.type]
        }

        if (state.style) {
            state.styles = [state.style]
        }
        if (state.searchQuery){
            state.searchQuery = [state.style]
        }

        delete params.class
        delete params.boundary
        delete params.type
        delete params.style
        params = new URLSearchParams(cleanup(params))
        
        Router.push(`/real-estate/buy/mls-search?${params.toString()}`)
    }

    const [showAdvance, setShowAdvance] = useState(false)
    const onAdvancedClick = () => setShowAdvance(true)

    return (
        <div>
            <Grid container spacing={2} justifyContent="space-between">
                <Grid item xs={12} md={8}>
                    <Radios
                        radio={<RedRadio />}
                        value={state.class}
                        onChange={onInputChange('class')}
                        options={CLASSES}
                    />
                    <Radios
                        radio={<RedRadio />}
                        value={state.offer}
                        onChange={onInputChange('offer')}
                        options={OFFERS}
                        className={classes.radio}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={4}
                    container
                    justify="flex-end"
                    alignItems="center"
                >
                    {/* <Typography variant="subtitle2">
                        {available} available properties
                    </Typography> */}
                </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={3}>
                    <BoundaryAutocomplete
                        boundaries={boundaries}
                        boundary={state.boundary}
                        onChange={onChange}
                        state={state}

                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <MyTextField
                        onChange={onInputChange('minBeds')}
                        value={state.minBeds}
                        label="Bedrooms"
                        id="bedrooms"
                        type="number"
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <MyTextField
                        onChange={onInputChange('minPrice')}
                        value={state.minPrice}
                        label="Price min"
                        id="min-price"
                        type="number"
                    />
                </Grid>
                {showAdvance && (
                    <>
                        <Grid item xs={12} md={3}>
                            <MyTextField
                                onChange={onInputChange('maxPrice')}
                                value={state.maxPrice}
                                label="Price max"
                                id="max-price"
                                type="number"
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <MyTextField
                                onChange={onInputChange('minBaths')}
                                value={state.minBaths}
                                label="Bathrooms"
                                id="bathrooms"
                                type="number"
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <MyTextField
                                onChange={onInputChange('maxDom')}
                                value={state.maxDom}
                                label="Days on Market (max)"
                                id="max-dom"
                                type="number"
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <MySelect
                                onChange={onInputChange('type')}
                                value={state.type}
                                label="Property Type"
                                options={
                                    state.class === 'Residential'
                                        ? lookups.types.residential
                                        : lookups.types.condo
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <MySelect
                                onChange={onInputChange('style')}
                                value={state.style}
                                label="Property Style"
                                options={
                                    state.class === 'Residential'
                                        ? lookups.styles.residential
                                        : lookups.styles.condo
                                }
                            />
                        </Grid>
                    </>
                )}
                <Grid
                    item
                    xs={12}
                    md={3}
                    container
                    direction="column"
                    alignItems="stretch"
                >
                    <Button
                        variant="contained"
                        color="gold"
                        onClick={onSubmit}
                        className={classes.submit}
                    >
                        Search
                    </Button>
                    {/* {!showAdvance && (
                        <Typography
                            variant="caption"
                            align="center"
                            onClick={onAdvancedClick}
                            className={classes.advanced}
                        >
                            Advanced Search
                        </Typography>
                    )} */}
                </Grid>
            </Grid>
        </div>
    )
}

const MySelect = ({ label, value, onChange, options, optional = true }) => {
    const id = `${label.replaceAll(' ', '-')}-select`
    return (
        <FormControl variant="filled" fullWidth>
            <InputLabel id={`${id}-label`}>{label}</InputLabel>
            <Select
                id={id}
                labelId={`${id}-label`}
                value={value}
                onChange={onChange}
                input={<MyStyledSelectInput />}
            >
                {optional && (
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                )}
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label
                            ? option.label
                            : option.value
                            ? option.value
                            : option}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

const BoundaryAutocomplete = ({ boundaries, boundary, onChange,state }) => {
    const handleChange = (_, newBoundary) => onChange('boundary', newBoundary)

    const [isOpen, setOpen] = useState(false)
    const open = () => setOpen(true)
    const close = () => setOpen(false)

    const [options, setOptions] = useState([])
    const [loading, setLoading] = useState(false)
    const handleTextFieldChange = (e) => {
        const newSearchQuery = e.target.value;
        onChange('searchQuery', newSearchQuery);
    };
    const getBoundaries = async () => {
        setLoading(true)
        try {
            const {
                data: { boundaries },
            } = await axios.get(
                'https://mss.rasti.ca/api/v1/properties/boundaries',
                {
                    withCredentials: false,
                }
            )
            setOptions(boundaries)
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        if (boundaries) {
            setOptions(boundaries)
        } else {
            getBoundaries()
        }
    }, [boundaries])

    const isOptionSelected = (option, value) => option.code === value.code
    const getOptionLabel = (option) => option.name

    return (
        // <Autocomplete
        //     open={isOpen}
        //     onOpen={open}
        //     onClose={close}
        //     options={options}
        //     getOptionSelected={isOptionSelected}
        //     getOptionLabel={getOptionLabel}
        //     value={boundary}
        //     onChange={handleChange}
        //     loading={loading}
        //     renderInput={(params) => (
        //         <MyTextField {...params} label="City / Neighborhood" />
        //     )}
        // />
        <MyTextField
            label="Address | Community | Postal code"
            value={state?.searchQuery}
            onChange={handleTextFieldChange}  // added this line
        />

        
    )
}

const Radios = ({ radio, value, onChange, options, ...props }) => (
    <FormControl component="fieldset" {...props}>
        <RadioGroup row value={value} onChange={onChange}>
            {options.map((option) => (
                <FormControlLabel
                    key={option.value}
                    value={option.value}
                    label={option.label}
                    control={radio}
                />
            ))}
        </RadioGroup>
    </FormControl>
)

const RedRadio = withStyles((theme) => ({
    root: {
        color: '#D9A06E',
        '&$checked': {
            color: '#D9A06E',
        },
    },
    checked: {},
}))(Radio)

const MyStyledSelectInput = withStyles({
    input: {
        backgroundColor: '#dfdfdf',
        borderRadius: 4,
        '&:focus': {
            backgroundColor: '#dfdfdf',
            borderRadius: 4,
        },
    },
})((props) => <FilledInput {...props} disableUnderline />)

const MyTextField = withStyles({
    root: {
        borderRadius: 4,
        backgroundColor: '#fff',
        '&:hover': {
            backgroundColor: '#fff',
        },
        '& label': {
            color: '#000',
        },
    },
})((props) => (
    <TextField
        {...props}
        variant="filled"
        fullWidth
        InputProps={{
            ...props.InputProps,
            disableUnderline: true,
        }}
    />
))

export default SearchBar

import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Link from '../General/Link'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    transition: '0.3s',
    '&:hover': {
      transform: 'scale(1.02)',
      boxShadow: '0 6px 10px 0 rgba(0, 0, 0, 0.2)',
    },
  },
  gridContainer: {
    padding: theme.spacing(2),
  },
  gridItem: {
    padding: theme.spacing(2),
  },
  media: {
    height: 200,
  },
  title: {
    fontWeight: 500,
    fontSize: 17
    // fontFamily: "'Helvetica', 'Arial', sans-serif",
  },
  date: {
    fontFamily: "'Helvetica', 'Arial', sans-serif",
  },
  readMore: {
    marginTop: theme.spacing(2),
    fontFamily: "'Helvetica', 'Arial', sans-serif",
    color: '#FFFFFF',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}))

const HomeRecentPosts = ({ posts }) => { // assuming posts is an array of posts
  const classes = useStyles()
  return (
    <Grid container className={classes.gridContainer}>
      {posts.map((post) => (
        <Grid item xs={12} sm={6} md={4} className={classes.gridItem} key={post.id}>
          <Card className={classes.root} elevation={2}>
            <CardActionArea component="a" href={`/blog/${post.id}/${post.title}`} target="_blank">
              <CardMedia
                className={classes.media}
                image={`https:${post.image.url}`}
                title={post.title}
              />
              <CardContent>
                <Typography gutterBottom variant="body1" component="p" className={classes.title}>
                  {post.title}
                </Typography>
                <Button className={classes.readMore} variant="contained">
                  Read More
                </Button>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}

export default HomeRecentPosts

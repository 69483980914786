import React from 'react'
import { useState } from 'react'

import Head from 'next/head'
import Link from 'next/link'
import Image from 'next/image'

import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { Box, Button, Divider, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

import { Arrow } from '../public/images/icons/arrow'
import line from '../public/images/line.png'
import propertyBanner from '../public/images/banner/propertyBanner.png'
import Logo from '../public/images/logo/Group 9.png'
// import LineImage from '../public/images/gline.png'

import Layout from '../src/components/General/Layout'
import FullCarousel from '../src/components/Carousel/FullCarousel'
import Listing from '../src/components/Sections/Listing'
import HomeRecentPosts from '../src/components/Items/HomeRecentPosts'
import HomeBannerSlider from '../src/components/Sections/Home/Slider'
import SearchBar from '../src/components/Sections/Home/Search'
import HomeMenu from '../src/components/Sections/Home/Menu'
import Testimonials from '../src/components/Sections/Home/Testimonials'
// import Splash from '../src/components/General/Splash'

import Client from '../src/services/network/Client'
import { getRecentPosts, normalizePosts } from '../src/services/contentful/Client'
// import { getPost, normalizePost ,getRecentInv } from '../src/services/contentful/Client'



const useStyles = makeStyles((theme) => {
    return ({
        searchbar: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(3, 0),
            backgroundColor: '#003366',
            color: '#fff',
        },
        main: {
            background: 'url(/images/homebg.jpeg)',
            width: '100%',
            backgroundSize: '100% 100%',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            height: 'fit-content',
            borderBottom: '8px solid #D9A06E',
            minHeight: '80vh',
            [theme.breakpoints.down('md')]: {
                backgroundSize: 'cover',
            },
        },
        background: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 0, 7, 0),
            color: '#fff',
            flexDirection: 'column',
            height: '100vh',
            height: 'fit-content',
        },
        logo: {
            marginTop: theme.spacing(14),
            maxWidth: '25%',
            height: 'fit-content',
            // [theme.breakpoints.down('md')]: {
            //     marginTop: theme.spacing(14),
            // },
            // [theme.breakpoints.up('mlg')]: {
            //     marginTop: theme.spacing(14),
            //     maxWidth: '40%',
            // },
        },
        content: {
            textAlign: 'center',
            marginTop: theme.spacing(5),
            [theme.breakpoints.up('mlg')]: {
                marginTop: theme.spacing(9),
            },
        },
        invest: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(4),
            [theme.breakpoints.up('mlg')]: {
                fontSize: '56px',
            },
        },
        // Typo; should be register
        RagisterToday: {
            width: '210px',
            height: '55px',
            background: '#C9996E',
            border: '0px solid #D9A06E',
            borderRadius: '0px',
            margin: theme.spacing(2.5, 0),
            borderColor: theme.palette.primary.light,
            color: theme.palette.primary.main,
            '&:hover': {
                background: '#916b43',
                '& .MuiButton-label': {
                    color: '#fff',
                },
            },
            [theme.breakpoints.up('mlg')]: {
                marginTop: theme.spacing(5),
                width: '258px',
                height: '68px',
            },
        },
        ToBecome: {
            color: '#C9996E',
            fontWeight: '500',
            fontSize: '17px',
            textAlign: 'center',
        },
        aboutContent: {
            maxWidth: '650px',
            marginLeft: theme.spacing(12),
            [theme.breakpoints.down('md')]: {
                width: '100%',
                padding: theme.spacing(2),
                margin: '0px',
            },
        },
        subHeadingContainer: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(3.5),
        },
        textContainer: {
            margin: theme.spacing(2.7, 3),
        },
        WhoAreYou: {
            fontSize: '14px',
            fontWeight: theme.typography.fontWeightRegular,
            marginLeft: theme.spacing(3),
        },
        ading: {
            fontWeight: '400',
            lineHeight: '44px',
            letterSpacing: '-0.03em',
            marginBottom: '30px',
        },
        readMoreBtn: {
            marginTop: theme.spacing(2.7),
            height: '14px',
            color: theme.palette.secondary.main,
            '&:hover': {
                background: '#EDF4FC',
                width: '145px',
                display: 'flex',
                justifyContent: 'space-between',
                animation: '$justify-animation 0.3s ease-in-out',
            },
            '&.MuiButton-text': {
                paddingLeft: '0px',
            },
        },
        aboutUs: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.palette.grey.dark,
            padding: '3% 0',
            gap: '0px',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                alignItems: 'center',
                padding: '0px',
            },
        },
        numberBoxContainer: {
            position: 'relative',
        },

        ready: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            padding: theme.spacing(5, 0, 7, 0),
            [theme.breakpoints.up('mlg')]: {
                padding: theme.spacing(10, 0, 15, 0),
            },
        },
        RealEstateHeading: {
            textAlign: 'center',
        },
        GetTheLatestInsights: {
            color: theme.palette.grey.light,
            textAlign: 'center',
            margin: theme.spacing(3, 0, 5, 0),
        },

        contactButton: {
            width: '210px',
            height: '55px',
            border: '1.5px solid #D9A06E',
            borderRadius: '0px',
            marginTop: theme.spacing(2),
            [theme.breakpoints.up('mlg')]: {
                marginTop: theme.spacing(5),
                width: '265px',
                height: '68px',
            },
            color: theme.palette.secondary.main,
            '&:hover': {
                background: theme.palette.primary.main,
                '& .MuiButton-label': {
                    color: '#fff',
                },
            },
            [theme.breakpoints.down('md')]: {
                marginTop: theme.spacing(3),
            },
        },

        line: {
            display: 'block',
            top: theme.spacing(-14),
            left: theme.spacing(-3),

            [theme.breakpoints.down('md')]: {
                display: 'none',
            },
        },
        ourlisting: {
            fontSize: '46px',
            color: '#003366',
            [theme.breakpoints.up('mlg')]: {
                fontSize: '56px',
            },
        },
        text: {
            color: '#003366',
            margin: theme.spacing(1, 3),
            width: '45%',
            fontFamily: "Inter', sans-serif",
            [theme.breakpoints.down('md')]: {
                width: '100%',
                textAlign: 'center',
            },
        },
        head: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            color: '#fff !important',
            margin: 'auto',
            [theme.breakpoints.up('mlg')]: {
                padding: theme.spacing(10.5, 0),
            },
            padding: theme.spacing(6.5, 0),
            [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
                width: '100%',
            },
        },
        line: {
            display: 'block',
            top: theme.spacing(-14),
            left: theme.spacing(-3),

            [theme.breakpoints.down('md')]: {
                display: 'none',
            },
        },
        fullCarouselImage: {
            width: '100%',      // Ensures the image takes up the full width of the carousel.
            height: '100%',     // Ensures the image takes up the full height of the carousel.
            objectFit: 'cover', // Ensures the image covers the full area, cropping if necessary.
            position: 'absolute' // Positions the image absolutely within its container.
        },
        carouselSlide: {
        position: 'relative', // Establishes a positioning context for absolutely positioned images.
        width: '100%',        // Ensures the slide takes up the full width of the carousel.
        height: '100vh',      // Sets the height of the slide to be full viewport height.
        overflow: 'hidden'    // Hides any overflow such as excess image height/width.
        }
    })
})


const MobileSlideContent = ({ currentSlideIndex }) => {
    const slideData = [
        {
            title: 'Experience Luxury at The Gates of Thornhill',
            desc: "Pre-booking now open for Marydel Homes' luxurious condos and townhouses in Vaughan. Claim your dream home, starting at just $449,900!",
            buttonText: 'Explore Project',
            onButtonClick: () =>
                window.open('project/category/pre-construction/1', '_blank'),
        },
        {
            title: "A Beginner's Guide",
            desc: "Investing in Real Estate: Building Wealth through Property",
            buttonText: 'Read More',
            onButtonClick: () =>
                window.open("/insights/1FYGJQbdV38fVTpVGg3ahO/A%20Beginner's%20Guide%20to%20Investing%20in%20Real%20Estate:%20Building%20Wealth%20through%20Property", '_blank'),
        },
        {
            title: 'Unlock Your Dream Home at Daniels Keelesdale 3',
            desc:"Embrace city living with Daniels FirstHome™ Keelesdale 3, a top-notch condo and townhouse community in Toronto. With prices starting from $545,900, you're one step away from your dream home.",
            buttonText: 'Discover More',
            onButtonClick: () =>
                window.open('project/category/pre-construction/2', '_blank'),
        },
        {
            title: 'Bayshire Realty',
            desc: "At Bayshire Realty, we are committed to enriching lives via the realm of real estate investment.",
            buttonText: 'About Us',
            onButtonClick: () =>
                window.open('team/about-us', '_blank'),
        },
        {
            title: 'Unveiling Sophistication: Heartlake Collection at UPtowns',
            desc:
                "Uncover luxury living at Brampton's new icon, Heartlake Collection at UPtowns. Spacious townhouses by Vandyk Properties, set for completion in 2025. Explore more today!",
            buttonText: 'View Details',
            onButtonClick: () =>
                window.open('project/category/pre-construction/3', '_blank'),
        },
    ];

    const { title, desc, buttonText, onButtonClick } = slideData[currentSlideIndex];

    return (
        // <div className="md:hidden mt-0" style={{backgroundColor:"#ECF4FC", marginTop: "-20px"}}>
        <div className="md:hidden mt-0">
            <div     
                className="flex flex-col items-start text-black p-4 w-full sm:w-3/4 md:w-3/5 lg:w-1/2 xl:w-2/5 2xl:w-1/3 mt-2"
                style={{ minHeight: '200px' }}
            >
                <h1 
                    className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-semibold mb-4" 
                    style={{ fontFamily: 'Roboto', lineHeight: '1.2', color: "#013466" }}
                >
                    {title}
                </h1>
                <h2 
                    className="text-sm sm:text-md md:text-lg lg:text-xl mb-4"
                    style={{ fontFamily: 'Arial', lineHeight: '1.2' }}
                >
                    {desc}
                </h2>
                <button
                    style={{background:'#D89F6D',color:'#003366'}} 
                    onClick={onButtonClick} 
                    className="w-40 px-2 py-2 text-sm md:text-md lg:text-lg bg-yellow-300 rounded shadow mt-4 self-start"
                >
                    {buttonText}
                </button>
            </div>
        </div>
    );
};

const HomePage = ({ stats: { available }, recentPosts }) => {
    const classes = useStyles()
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

    const onSlideChange = (slideIndex) => {
        setCurrentSlideIndex(slideIndex);
    };
    
    // const [isSplash, setIsSplash] = useState(true)
    const [isHoverd, setIsHoverd] = useState(false)
    const [isConnectHoverd, setIsConnectHoverd] = useState(false)

    // const onChangeSplash = function (value) {
    //     setIsSplash(value)
    // }

    // Comment for testing

    return (
        <>
            {/* {isSplash ? <Splash onChangeSplash={onChangeSplash} /> :
                <div
                    style={{
                        opacity: isSplash ? "0" : "1",
                        transition: "all .2s",
                        visibility: isSplash ? "hidden" : "visible",
                    }}
                > */}
                <div>


                    <Layout>
                    {/* <div style={{position: 'fixed', top: 0, left: 0, height: '100vh', width: '10px', zIndex: 1000}}>
                        <img src="/images/line.png" style={{objectFit: 'cover', height: '100%', width: '100%'}} alt="line"/>
                    </div> */}

                        <Head>
                            <title>
                            Bayshirerealty | To Invest In Real Estate With Confidence
                            </title>
                            <meta
                                name="description"
                                content={`${available} homes for sale in the GTA – browse photos of New Toronto Real Estate & MLS Listings | Live Pricing Updates & User Friendly`}
                            />
                        </Head>
                        
                        {/* <HomeBannerSlider /> */}
                        <FullCarousel
                            isPlaying
                            interval={5000}
                            height="100%"
                            buttons={true}
                            showDots
                            onSlideChange={onSlideChange} 
                            style={{backgroundColor:"#ECF4FC"}} 
                            className={classes.carousel} // Apply the carousel styles
                            slides={[
                                {
                                    image: 'https://images.ctfassets.net/wvo2vg8e6aqd/6l2J0bwUcbaRkVFlVsLpMu/9865069a69b449b8faac23f757f3968d/2023_05_12_02_47_52_gates_of_thornhill_condo_rendering.webp',
                                    title: 'Experience Luxury at The Gates of Thornhill',
                                    desc: "Pre-booking now open for Marydel Homes' luxurious condos and townhouses in Vaughan. Claim your dream home, starting at just $449,900!",
                                    buttonText:'Explore Project',
                                    onButtonClick: () => window.open('project/category/pre-construction/1', '_blank'),
                                    className: classes.image
                                },
                                {
                                    title: "A Beginner's Guide",
                                    desc: "Investing in Real Estate: Building Wealth through Property",
                                    buttonText: 'Read More',
                                    image: 'https://images.ctfassets.net/wvo2vg8e6aqd/7rNauJUFceT0MoCxiMuIrr/2ba39336aa10ab040d2fdf9f49d0c9c8/2023_01_19_02_57_07_back_to_back-elevationa.webp',
                                    onButtonClick: () =>
                                        window.open("/insights/1FYGJQbdV38fVTpVGg3ahO/A%20Beginner's%20Guide%20to%20Investing%20in%20Real%20Estate:%20Building%20Wealth%20through%20Property", '_blank'),
                                        className: classes.image
                                },
                                {
                                    image: 'https://images.ctfassets.net/wvo2vg8e6aqd/5bbIhE15vTIbVFGzeCZs8a/1e0f4201978006d8672d2f46535c0c2e/2022_03_24_08_47_07_1645551148-adhoc-kee-c12-hero-0222-final02__rs.webp',
                                    title: 'Unlock Your Dream Home at Daniels Keelesdale 3',
                                    desc:"Embrace city living with Daniels FirstHome™ Keelesdale 3, a top-notch condo and townhouse community in Toronto. With prices starting from $545,900, you're one step away from your dream home.",
                                    buttonText:'Discover More',
                                    onButtonClick: () => window.open('project/category/pre-construction/2', '_blank'),
                                    className: classes.image
                                },
                                {
                                    title: 'Bayshire Realty',
                                    desc: "At Bayshire Realty, we are committed to enriching lives via the realm of real estate investment.",
                                    image: 'https://images.ctfassets.net/wvo2vg8e6aqd/7rNauJUFceT0MoCxiMuIrr/2ba39336aa10ab040d2fdf9f49d0c9c8/2023_01_19_02_57_07_back_to_back-elevationa.webp',
                                    buttonText: 'About Us',
                                    onButtonClick: () =>
                                        window.open('team/about-us', '_blank'),
                                        className: classes.image
                                },
                                {
                                    image: 'https://images.ctfassets.net/wvo2vg8e6aqd/7rNauJUFceT0MoCxiMuIrr/2ba39336aa10ab040d2fdf9f49d0c9c8/2023_01_19_02_57_07_back_to_back-elevationa.webp',
                                    title: 'Unveiling Sophistication: Heartlake Collection at UPtowns',
                                    desc:"Uncover luxury living at Brampton's new icon, Heartlake Collection at UPtowns. Spacious townhouses by Vandyk Properties, set for completion in 2025. Explore more today!",
                                    buttonText:'View Details',
                                    onButtonClick: () => window.open('project/category/pre-construction/3', '_blank'),
                                    className: classes.image
                                },
                            ]}
                        />
                        <MobileSlideContent currentSlideIndex={currentSlideIndex} style={{marginTop:"1000px"}} />
                        <div className={classes.searchbar}>
                            <Container>
                                <SearchBar total={available} />
                            </Container>
                        </div>
                        <Box className={classes.aboutUs}>
                            <Box className={classes.numberBoxContainer}>
                                <Image src={propertyBanner} width={484} height={524} />
                            </Box>


                            <Box className={classes.aboutContent}>
                                <Box className={classes.subHeadingContainer}>
                                    <Box>
                                        <Image src={line} height={30} width={6} />
                                    </Box>

                                    <Typography
                                        variant="body2"
                                        color="secondary"
                                        className={classes.WhoAreYou}
                                    >
                                        WHO WE ARE
                                    </Typography>
                                </Box>
                                <Typography
                                    variant="h4"
                                    color="secondary"
                                    className={classes.heading}
                                >
                                    We dominate the market by focusing on our investors
                                    needs and increasing their wealth through real estate
                                    investing worldwide.
                                </Typography>
                                <Box className={classes.textContainer}>
                                    <Typography
                                        variant="body1"
                                        style={{ color: '#3A3A3A' }}
                                    >
                                        At Bayshire Realty, our mission is to improve the
                                        lives of our clients by enhancing the experience of
                                        purchasing and selling real estate for investment
                                        purposes.
                                    </Typography>
                                    <Box>
                                        <Link href={`/team/about-us`}>
                                            <Button className={classes.readMoreBtn}>
                                                Read More
                                                <Arrow color="#003466" />
                                            </Button>
                                        </Link>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box className={classes.ready} position={'relative'}>
                            <Typography className={classes.RealEstateHeading} variant="h2">
                                Are you ready to learn more about
                                <br />
                                Real Estate Investing?
                            </Typography>
                            <Typography
                                className={classes.GetTheLatestInsights}
                                variant={'body2'}
                            >
                                GET THE LATEST INSIGHTS
                            </Typography>
                            <Link href={`/team/contact-us`}>
                                <Button
                                    className={classes.contactButton}
                                    onMouseOver={() => {
                                        setIsConnectHoverd(true)
                                    }}
                                    onMouseLeave={() => setIsConnectHoverd(false)}
                                >
                                    Connect with us
                                    <Arrow
                                        color={isConnectHoverd ? '#fff' : '#003466'}
                                        className={classes.Arrow}
                                    />
                                </Button>
                            </Link>
                        </Box>
                        <Listing />

                        {/* <HomeMenu /> */}
                        <Testimonials />

                        <Grid item xs={12} md={12} style={{paddingBottom:50}}>
                            <Container className={classes.head}>
                                <Box position={'relative'}>
                                    {/* <Box position={'absolute'} className={classes.line}>
                                        <Image src={Line} width={8} height={185}></Image>
                                    </Box> */}

                                    <Typography variant="h2" className={classes.ourlisting}>
                                        Recent Insights
                                    </Typography>
                                </Box>

                                {/* <Typography className={classes.text} variant="body1">
                                    Read our latest insughts from the world of real estate investing.
                                </Typography> */}
                            </Container>


                            {/* <Typography variant="h4" align="center" gutterBottom>
                                Recent Posts
                            </Typography> */}
                            <HomeRecentPosts posts={recentPosts} />
                        </Grid>


                    </Layout>
                </div>
           </>
    )
}

// noinspection JSUnusedGlobalSymbols
export async function getStaticProps() {
    const props = {
        stats: {
            available: 30000,
        },
    }
    const recentPostsResponse = await getRecentPosts(6)
    props.recentPosts = normalizePosts(recentPostsResponse.items)
    try {
        const { data } = await Client.get('/v1/properties/stats')
        props.stats.available = data.available
    } catch (e) { }

    return {
        props,
        revalidate: 21700, // 6 hours
    }
}

export default HomePage

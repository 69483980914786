const TESTIMONIALS = [
    {
        text: 'Mase is very polite, very direct, and straightforward. He is trustworthy and very knowledgeable about the market. I enjoyed working with him.',
        author: 'Erica Hazelton',
    },
    {
        text: "We had a great experience working with Mase. He is very knowledgeable and talented in his field. Mr. Rasti is very professional with his work and gives special attention to the client's individual needs. We highly recommend him.",
        author: 'Jaspal Kapoor',
    },
    {
        text: 'Mase was professional, knowledgeable, highly effective in selling our property, and I would highly recommend him when buying or selling a property.',
        author: 'Frank Marchinano',
    },
    {
        text: 'We enjoyed working with Mase and found him to be caring professional and very hard working. It was a pleasure working with him, he made the selling of our home very easy.',
        author: 'Delory & Carene Bailey',
    },
    {
        text: 'We really like working with Mase. He is organized, professional, and knows the real estate market very well. We most definitely will work with Mase again, and highly recommend him to our family and friends.',
        author: 'Cheryl & Andrew Naylor',
    },
    {
        text: 'Professional, focused, and organized; Mase gets the results through his strong work ethic, excellent negotiation & mediation skills, and attention to detail. Mase is caring, responsive, and provides excellent customer service.',
        author: 'Kate & Robb Doyle',
    },
    {
        text: 'I sold two of my properties with Mase within a few weeks of each other and was very impressed with his commitment to me as a client, his knowledge of the market, and his hard work. Mase is an exceptional realtor and a true professional.',
        author: 'Tony Paglialunga',
    },
]

export default TESTIMONIALS

import React from 'react'
import Image from 'next/image'

import { CarouselProvider, Slider, Slide, DotGroup, ButtonBack, ButtonNext } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'

import { makeStyles } from '@material-ui/core/styles'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { useTheme, useMediaQuery } from '@material-ui/core';



import Banner from '../../../../public/images/banner/mase-rasti-team-welcome-to-gta-real-estate-banner.jpg'
import img1 from '../../../../public/images/banner/img1.jpg'
import img2 from '../../../../public/images/banner/img2.jpg'
import img3 from '../../../../public/images/banner/img3.jpg'

const IMAGES = [img1, img2, img3]
// const IMAGES = [Banner, Banner, Banner]
const useStyles = makeStyles((theme) => ({
    sliderWrapper: {
        position: 'relative',
        padding: theme.spacing(2),
    },
    dotGroup: {
        textAlign: 'center',
        marginTop: theme.spacing(1),
    },
    // buttonsContainer: {
    //     position: 'absolute',
    //     left: '10%', // center horizontally
    //     bottom: '4%',
    //     transform: 'translateX(-50%)', // adjust for the container's own width
    //     display: 'flex',
    //     alignItems: 'center', // align the buttons vertically
    // },
    // navButton: {
    //     backgroundColor: '#003366',
    //     color: '#D89F6D', 
    //     borderRadius: '20%', // make buttons round
    //     boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)', // add a shadow
    //     transition: 'all 0.3s ease-in-out', // smooth transition
    //     '&:hover': {
    //         backgroundColor: '#D89F6D',
    //         color: '#003366', 
    //     },
    // },
    // backButton: {
    //     padding: props => props.isXSmallScreen ? theme.spacing(0.3) : props.isSmallScreen ? theme.spacing(0.6) : theme.spacing(1),
    // },
    // nextButton: {
    //     backgroundColor: '#D89F6D',
    //     color: '#003366',
    //     padding: props => props.isXSmallScreen ? theme.spacing(0.3) : props.isSmallScreen ? theme.spacing(0.6) : theme.spacing(1),
    //     '&:hover': {
    //         backgroundColor: '#003366',
    //         color: '#D89F6D',
    //     },
    // },
    // navIcon: {
    //     fontSize: props => props.isXSmallScreen ? '1rem' : props.isSmallScreen ? '1.5rem' : '2rem',
    // },
}))

const HomeBannerSlider = () => {
    const theme = useTheme();
    const isXSmallScreen = useMediaQuery(theme.breakpoints.down('xs')); // <= 360px
    const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm')); // > 360px and <= 600px
    const classes = useStyles({ isXSmallScreen, isSmallScreen });

    return (
        <div className={classes.sliderWrapper}>
            <CarouselProvider
                naturalSlideWidth={1600}
                naturalSlideHeight={595}
                totalSlides={IMAGES.length}
                dragEnabled
                touchEnabled
                infinite
                isPlaying
                interval={3000}
            >
                <Slider>
                    {IMAGES.map((image, index) => (
                        <Slide key={index} index={index}>
                        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                            <Image
                                src={image}
                                alt="Bayshire Real Estate - North Toronto Realtor"
                                layout="fill"
                                objectFit="contain"
                            />
                        </div>
                    </Slide>
                    ))}
                </Slider>
                <div className={classes.buttonsContainer}>
                {/* <div className={classes.buttonsContainer}>
                    <ButtonBack className={`${classes.navButton} ${classes.backButton}`}>
                        <NavigateBeforeIcon className={classes.navIcon} />
                    </ButtonBack>
                    <ButtonNext className={`${classes.navButton} ${classes.nextButton}`}>
                        <NavigateNextIcon className={classes.navIcon} />
                    </ButtonNext>
                </div> */}

                </div>

                <DotGroup className={classes.dotGroup} />
            </CarouselProvider>
        </div>
    )
}

export default HomeBannerSlider
const types = {
    residential: [
        { id: 15, value: 'Att/Row/Twnhouse' },
        { id: 27, value: 'Semi-Detached' },
        { id: 17, value: 'Detached' },
        { id: 18, value: 'Farm' },
        { id: 20, value: 'Duplex' },
        { id: 21, value: 'Triplex' },
        { id: 24, value: 'Fourplex' },
        { id: 23, value: 'Multiplex' },
        { id: 30, value: 'Store w/Apt/Offc' },
        { id: 26, value: 'Rural Resid' },
        { id: 22, value: 'Link' },
        { id: 28, value: 'Vacant Land' },
    ],
    condo: [
        { id: 48, value: 'Parking Space' },
        { id: 52, value: 'Vacant Land Condo' },
        { id: 53, value: 'Locker' },
        { id: 54, value: 'Condo Apt' },
        { id: 57, value: 'Condo Townhouse' },
        { id: 60, value: 'Det Condo' },
        { id: 66, value: 'Semi-Det Condo' },
    ],
}

const styles = {
    residential: [
        { id: 31, value: '1 1/2 Storey' },
        { id: 32, value: '2 1/2 Storey' },
        { id: 33, value: '2-Storey' },
        { id: 34, value: '3-Storey' },
        { id: 35, value: 'Apartment' },
        { id: 36, value: 'Bachelor/Studio' },
        { id: 37, value: 'Backsplit 3' },
        { id: 38, value: 'Backsplit 4' },
        { id: 39, value: 'Backsplit 5' },
        { id: 40, value: 'Bungaloft' },
        { id: 41, value: 'Bungalow' },
        { id: 42, value: 'Bungalow-Raised' },
        { id: 43, value: 'Loft' },
        { id: 45, value: 'Sidesplit 3' },
        { id: 46, value: 'Sidesplit 4' },
        { id: 47, value: 'Sidesplit 5' },
    ],
    condo: [
        { id: 74, value: '2-Storey' },
        { id: 75, value: '3-Storey' },
        { id: 76, value: 'Apartment' },
        { id: 77, value: 'Bachelor/Studio' },
        { id: 78, value: 'Bungaloft' },
        { id: 79, value: 'Bungalow' },
        { id: 80, value: 'Industrial Loft' },
        { id: 81, value: 'Loft' },
        { id: 82, value: 'Multi-Level' },
        { id: 84, value: 'Stacked Townhse' },
        { id: 85, value: 'Warehouse Loft' },
    ],
}

const basements = [
    { id: 1, value: 'Apartment' },
    { id: 2, value: 'Crawl Space' },
    { id: 3, value: 'Fin W/O' },
    { id: 4, value: 'Finished' },
    { id: 5, value: 'Full' },
    { id: 6, value: 'Half' },
    { id: 7, value: 'None' },
    { id: 8, value: 'Other' },
    { id: 9, value: 'Part Bsmt' },
    { id: 10, value: 'Part Fin' },
    { id: 11, value: 'Sep Entrance' },
    { id: 12, value: 'Unfinished' },
    { id: 13, value: 'W/O' },
]

const statuses = [
    { id: 86, value: 'Dft' },
    { id: 88, value: 'Ext' },
    { id: 90, value: 'New' },
    { id: 91, value: 'Pc' },
    { id: 92, value: 'Sc' },
]

export default { types, styles, basements, statuses }

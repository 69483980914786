import { Box, Button, Container, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import img from '../../../public/images/banner/img.png'
import img2 from '../../../public/images/banner/img2.png'
import img3 from '../../../public/images/banner/img3.png'
import img4 from '../../../public/images/banner/img4.png'
import ListingCard from '../Items/ListingCard'
import Grid from '@material-ui/core/Grid'
import Line from '../../../public/images/line.png'
import Image from 'next/image'
import { Arrow } from '../../../public/images/icons/arrow'
import Link from 'next/link'
const useStyles = makeStyles((theme) => ({
    Listing: {
        width: '100%',
        backgroundColor: theme.palette.secondary.main,
        height: 'fit-content !important',
        paddingBottom: theme.spacing(8),
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2, 1),
        },
    },
    ourlisting: {
        fontSize: '46px',
        color: '#ffff',
        [theme.breakpoints.up('mlg')]: {
            fontSize: '56px',
        },
    },
    text: {
        color: '#FFFF',
        margin: theme.spacing(1, 3),
        width: '45%',
        fontFamily: "Inter', sans-serif",
        [theme.breakpoints.down('md')]: {
            width: '100%',
            textAlign: 'center',
        },
    },
    head: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        color: '#fff !important',
        margin: 'auto',
        [theme.breakpoints.up('mlg')]: {
            padding: theme.spacing(10.5, 0),
        },
        padding: theme.spacing(6.5, 0),
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            width: '100%',
        },
    },
    cards: {
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(1),
        },
    },
    line: {
        display: 'block',
        top: theme.spacing(-14),
        left: theme.spacing(-3),

        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },

    RegisterTodayBtn: {
        width: '210px',
        height: '55px',
        color: '#fff',
        border: ' 1px solid #8E735A',
        borderRadius: '0px',
        marginBottom: theme.spacing(2.5),
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
        [theme.breakpoints.up('mlg')]: {
            marginTop: theme.spacing(5),
            width: '225px',
            height: '68px',
        },
    },

    ListingCard: {
        fontFamily: "'Inter', sans-serif",
        fontSize: '17px',
        fontWeight: '400',
        lineHeight: '40px',
        letterSpacing: '0.07em',
    },
}))
const Listing = () => {
    const classes = useStyles()

    return (
        <Box className={classes.Listing}>
            <Container className={classes.head}>
                <Box position={'relative'}>
                    <Box position={'absolute'} className={classes.line}>
                        <Image src={Line} width={8} height={185}></Image>
                    </Box>

                    <Typography variant="h2" className={classes.ourlisting}>
                        Our Listings
                    </Typography>
                </Box>

                <Typography className={classes.text} variant="body1">
                    At Bayshire Realty our vision is to be a leading firm
                    amongst investors with their real estate investment
                    transactions.
                </Typography>
            </Container>
            <Container>
                <Grid container spacing={1} className={classes.cards}>
                    {/* <Grid
                        item
                        md={3}
                        sm={6}
                        xs={12}
                        className={classes.ListingCard}
                    >
                        <ListingCard
                            imgurl={img3}
                            title="Commercial"
                            className={classes.ListingCard}
                        />
                    </Grid> */}
                    <Grid
                        item
                        md={4}
                        sm={6}
                        xs={12}
                        className={classes.ListingCard}
                    >
                        <ListingCard
                            imgurl={img4}
                            title="Pre-Construction"
                            linkUrl={'/project/category/pre-construction'}
                        />
                    </Grid>
                    <Grid
                        item
                        md={4}
                        sm={12}
                        xs={12}
                        className={classes.ListingCard}
                    >
                        <ListingCard imgurl={img} title="Land" linkUrl={'/project/category/pre-construction'} />
                    </Grid>
                    <Grid
                        item
                        md={4}
                        sm={6}
                        xs={12}
                        className={classes.ListingCard}
                    >
                        <ListingCard
                            imgurl={img2}
                            title={'All Listings'}
                            linkUrl={'/project/category'}
                        />
                    </Grid>

                </Grid>
            </Container>
            {/* <Box
                display={'flex'}
                alignItems="center"
                justifyContent={'center'}
                flexDirection="column"
                padding="20px"
            >
                <Link href={`/auth/register?next=/property`}>
                    <Button className={classes.RegisterTodayBtn}>
                        Register Today
                        <Arrow color="#fff" />
                    </Button>
                </Link>
                <Typography
                    variant="body1"
                    style={{ color: '#fff' }}
                    align="center"
                >
                    To access our complete list of investment properties
                </Typography>
            </Box> */}
        </Box>
    )
}

export default Listing

import * as React from 'react'
import {
    Card,
    CardContent,
    CardMedia,
    CardActionArea,
    Typography,
    Box,
} from '@material-ui/core'
import Link from 'next/link'

export default function ListingCard({ imgurl, title, className, linkUrl }) {
    return (
        <Box style={{ backgroundColor: 'unset' }}>
            <Link href={linkUrl} passHref>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        height="400rem"
                        width="100px !important"
                        image={imgurl.src}
                        alt="green iguana"
                        className={className}
                    />
                    <CardContent style={{ backgroundColor: 'unset' }}>
                        <Box
                            display={'flex'}
                            justifyContent="center"
                            sx={{ textTransform: 'uppercase' }}
                        >
                            <Typography
                                variant="body2"
                                style={{ color: '#fff', cursor: 'pointer' }}
                            >
                                {title}
                                <span
                                    style={{
                                        fontSize: '25px',
                                        color: '#D9A06E',
                                        marginLeft: '1rem',
                                    }}
                                >
                                    +
                                </span>
                            </Typography>
                        </Box>
                    </CardContent>
                </CardActionArea>
            </Link>
        </Box>
    )
}

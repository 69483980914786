import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { Slider, CarouselProvider, Slide, DotGroup } from 'pure-react-carousel'
import clsx from 'clsx'

import 'pure-react-carousel/dist/react-carousel.es.css'

import TESTIMONIALS from '../../../helpers/TestimonialsData'

const useStyles = makeStyles((theme) => ({
    '@global': {
        '.carousel__dot--selected': {
            background: '#5ba1e3 !important',
        },
        '.carousel__dot': {
            background: '#151515', // Set color of inactive dots
        },
    },
    container: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
    },
    counter: {
        width: '100%',
        display: 'flex',
        alignItems: 'stretch',
        fontSize: 45,
        textAlign: 'center',
        color: '#fff',
        backgroundColor: theme.palette.primary.main,
        margin: theme.spacing(3, 0, 1, 0),
    },
    count: {
        flex: 1,
        '&.dark': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    dots: {
        display: 'flex',
        justifyContent: 'center',
    },
    testimonial: {
        width: '100%',
        padding: theme.spacing(3),
    },
    card: {
        width: '100%',
    },
}))

const HomeTestimonials = () => {
    const classes = useStyles()

    return (
        <Container className={classes.container}>
            <Grid container spacing={5}>
                <Grid item xs={12} md={3}>
                    <Typography
                        variant="h4"
                        component="h4"
                        color="primary"
                        className="uppercase"
                        gutterBottom
                    >
                        Testimonials
                    </Typography>
                    <Typography variant="body1" component="p" gutterBottom>
                        Don&apos;t take our word. See what our clients
                        said about us.
                    </Typography>
                    {/* <Counter />
                    <Typography
                        variant="subtitle1"
                        component="p"
                        align="center"
                        className="uppercase"
                    >
                        Homes Sold
                    </Typography> */}
                </Grid>
                <Grid item xs={12} md={9}>
                    {TESTIMONIALS.length === 0 ? (
                        <p>No testimonials available to show!</p>
                    ) : (
                        <TestimonialsSlider testimonials={TESTIMONIALS} />
                    )}
                </Grid>
            </Grid>
        </Container>
    )
}

const TestimonialsSlider = ({ testimonials }) => {
    const classes = useStyles()
    const theme = useTheme()

    const mdup = useMediaQuery(theme.breakpoints.up('md'))
    // noinspection JSCheckFunctionSignatures
    const msmup = useMediaQuery(theme.breakpoints.up('msm'))
    const smup = useMediaQuery(theme.breakpoints.up('sm'))
    // noinspection JSCheckFunctionSignatures
    const xsmup = useMediaQuery(theme.breakpoints.up('xsm'))
    // noinspection JSCheckFunctionSignatures
    const mxsup = useMediaQuery(theme.breakpoints.up('mxs'))

    return (
        <CarouselProvider
            naturalSlideHeight={500}
            naturalSlideWidth={
                mdup
                    ? 2000
                    : msmup
                    ? 1500
                    : smup
                    ? 1000
                    : xsmup
                    ? 750
                    : mxsup
                    ? 500
                    : 200
            }
            totalSlides={testimonials.length}
            isPlaying
            interval={5000}
            infinite
        >
            <Slider>
                {testimonials.map((t, i) => (
                    <Slide index={i} key={t.author}>
                        <div className={classes.testimonial}>
                            <Testimonial testimonial={t} />
                        </div>
                    </Slide>
                ))}
            </Slider>
            <div className={classes.dots}>
                <DotGroup />
            </div>
        </CarouselProvider>
    )
}

const Testimonial = ({ testimonial }) => {
    const classes = useStyles()
    return (
        <Card square className={classes.card}>
            <CardContent>
                <Typography variant="body1" component="p" gutterBottom>
                    {testimonial.text}
                </Typography>
                <Typography variant="subtitle2" component="p" align="right">
                    <b>{testimonial.author}</b>
                </Typography>
            </CardContent>
        </Card>
    )
}

const Counter = () => {
    const classes = useStyles()
    return (
        <div className={classes.counter}>
            <div className={clsx(classes.count, 'dark')}>3</div>
            <div className={classes.count}>2</div>
            <div className={clsx(classes.count, 'dark')}>1</div>
            <div className={classes.count}>8</div>
        </div>
    )
}

export default HomeTestimonials

import React from 'react'
import Image from 'next/image'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import Link from '../../General/Link'

import Image1 from '../../../../public/images/menu/mase-rasti-whats-my-home-worth-free-evaluation.png'
import Image2 from '../../../../public/images/menu/mase-rasti-exclusive-off-market-listings.png'
import Image3 from '../../../../public/images/menu/mase-rasti-sold-data.png'
import Image4 from '../../../../public/images/menu/mase-rasti-real-estate-toronto-homes-under-million.png'
import Image5 from '../../../../public/images/menu/mase-rasti-luxury-real-estate-toronto.png'
import Image6 from '../../../../public/images/menu/mase-rasti-condo-apartments-townhouses-toronto.png'
import ImageDots from '../../../../public/images/assets/dots.png'

const useStyles = makeStyles((theme) => ({
    primaryMenu: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
    },
    secondaryMenu: {
        color: '#fff',
        backgroundColor: '#003466',
        padding: theme.spacing(5, 0),
        position: 'relative',
        '& .container': {
            position: 'relative',
            zIndex: 10,
        },
        '& .dots': {
            position: 'absolute',
            bottom: theme.spacing(5),
            left: theme.spacing(5),
            zIndex: 0,
        },
    },
    image: {
        width: '100%',
        objectFit: 'cover',
        display: 'block',
        borderRadius: 3,
        marginBottom: theme.spacing(3),
    },
}))

const MENU1 = [
    {
        link: '/real-estate/sell/evaluation',
        image: {
            src: Image1,
            alt: 'Free Home Evaluation and Value Report by Bayshire Team',
        },
        title: "What's My Home Worth?",
    },
    {
        link: '/real-estate/sell/services',
        image: {
            src: Image2,
            alt: 'Off-Market Exclusive Listings in Toronto, Mississauga, Oakville - Bayshire Team',
        },
        title: 'Sell Your Home Privately',
    },
    {
        link: '/real-estate/sell/evaluation',
        image: {
            src: Image3,
            alt: 'Sold MLS Data in Toronto, Mississauga, Oakville - Bayshire Team',
        },
        title: 'Find out What your Neighbor Sold for',
    },
]

const MENU2 = [
    {
        link: '/real-estate/buy/mls-search?maxPrice=1000000&offer=Sale&classes=Residential&boundaryName=Toronto&area=01',
        image: {
            src: Image4,
            alt: 'Houses and Condos under a million in Downtown Toronto and Mississauga by Bayshire Team',
            height: 300,
        },
        title: 'Homes in Toronto Under a Million',
    },
    {
        link: '/real-estate/buy/mls-search?minPrice=1000000',
        image: {
            src: Image5,
            alt: 'Luxury Real Estate Properties, House and Condo in Toronto, Mississauga and Oakville by Bayshire Team',
            height: 400,
        },
        title: 'Luxury Real Estate in Toronto',
    },
    {
        link: '/real-estate/buy/mls-search',
        image: {
            src: Image6,
            alt: 'Buy or Lease Condo Apartments and Townhouses in Toronto, Mississauga and Oakville',
            height: 500,
        },
        title: 'Condos Apartments & Townhouses in Toronto',
    },
]

const HomeMenu = () => {
    const classes = useStyles()

    return (
        <>
            <Container className={classes.primaryMenu}>
                <Grid container spacing={5} alignItems="stretch">
                    {MENU1.map((m, index) => (
                        <Grid key={`menu1_${index}`} item xs={12} md>
                            <Link href={m.link}>
                                <Image
                                    src={m.image.src}
                                    alt={m.image.alt}
                                    placeholder="blur"
                                    width={400}
                                    height={300}
                                    className={classes.image}
                                />
                                <Typography
                                    variant="h5"
                                    component="h3"
                                    align="center"
                                    className="uppercase"
                                >
                                    <b>{m.title}</b>
                                </Typography>
                            </Link>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <div className={classes.secondaryMenu}>
                <span className="dots">
                    <Image
                        src={ImageDots}
                        alt="Bayshire Team"
                        placeholder="blur"
                        width={200}
                        height={200}
                    />
                </span>
                <Container className="container">
                    <Grid container spacing={5} alignItems="stretch">
                        {MENU2.map((m, index) => (
                            <Grid key={`menu2_${index}`} item xs={12} md>
                                <Link href={m.link}>
                                    <Image
                                        src={m.image.src}
                                        alt={m.image.alt}
                                        placeholder="blur"
                                        width={400}
                                        height={m.image.height}
                                        className={classes.image}
                                    />
                                    <Typography
                                        variant="h5"
                                        component="h3"
                                        align="center"
                                        className="uppercase"
                                    >
                                        <b>{m.title}</b>
                                    </Typography>
                                </Link>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </div>
        </>
    )
}

export default HomeMenu
